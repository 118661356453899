import React from "react";
import styles from "./Banner.module.scss";

const Banner = () => (
  <div className={styles.banner}>
    <div className={styles.content}>
      <p>This category does not have posts yet.</p>
      <span>
        <p>Come back later</p>
      </span>
    </div>
  </div>
);

export default Banner;
