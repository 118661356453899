import React from "react";
import { navigate } from "gatsby";

import styles from "./mobile-dropdown.module.scss";

function MobileDropdown({ nodes, url }) {
    
  const handleChange = e => {
    const { value } = e.target;
    navigate(`/blog/${value}`);
  };

  return (
    <div className={styles.navigation}>
      <label>Categories</label>
      <select
        className={styles.select}
        value={url}
        onChange={handleChange}
      >
        <option value="all">All</option>
        {nodes.map(category => (
          <option key={category.id} value={category.url}>
            {category.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MobileDropdown;
