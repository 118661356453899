const breakPoints = [
  {
    breakpoint: 1025,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      centerMode: true,
      centerPadding: "0px",
    },
  },
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      centerMode: true,
      centerPadding: "0px",
    },
  },
  {
    breakpoint: 750,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 2,
      centerMode: true,
      centerPadding: "0px",
    },
  },
];

export default breakPoints;
