import React from "react";
import MobileDropdown from "./mobileDropdown/MobileDropdown";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./BlogNavigation.module.scss";
import { Link } from "gatsby";

import mobileCheck from "../../../../utils/mobileCheck";

const BlogNavigation = ({ url }) => {
  const isMobile = mobileCheck();

  const {
    tableData: { nodes },
  } = useStaticQuery(graphql`
    query {
      tableData: allContentfulCategories(filter: { active: { eq: true } }) {
        nodes {
          id
          name
          url
        }
      }
    }
  `);

  return (
    <div className={styles.navigation}>
      {!isMobile ? (
        <>
          <Link
            to="/blog/all"
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            All
          </Link>
          {nodes.length &&
            nodes.map(category => (
              <Link
                key={category.id}
                to={`/blog/${category.url}`}
                className={styles.link}
                activeClassName={styles.activeLink}
              >
                {category.name}
              </Link>
            ))}
        </>
      ) : (
        url && <MobileDropdown nodes={nodes} url={url} />
      )}
    </div>
  );
};

export default BlogNavigation;
