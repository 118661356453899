import React from "react";
import mobileCheck from "../../../../../utils/mobileCheck";
import breakPoints from "./settings";
import { Link } from "gatsby";
import styles from "./PostsCarousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PostsCarousel = ({ posts }) => {
  const isMobile = mobileCheck();

  const settings = {
    dots: false,
    infinite: posts.length <= 1 && !isMobile ? false :true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: !isMobile ? "120px" : "0px",
    autoplay: false,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: breakPoints,
    swipe: isMobile,
    focusOnSelect: true,
  };


  return (
    <div className={styles.container}>
      <h2>FEATURED POSTS</h2>
      <Slider {...settings}>
        {posts &&
          posts.map(post => {
            return (
              <div className={styles.content} key={post.id}>
                <div className={styles.imageContainer}>
                  <img
                    src={post.image.fluid.src}
                    className={styles.image}
                    alt={post.title}
                  />
                </div>
                <div className={styles.header}>
                  <h3>{post.title.substr(0,35)}{post.title.length > 35 && "..."}</h3>
                  <h4>{post.author}</h4>
                  <h4>{post.createdAt}</h4>
                </div>
                <div className={styles.description}>
                  <p>
                    {post.description.substr(0, 80)}
                    {"..."}
                  </p>
                </div>
                <div className={styles.buttonBox}>
                  <Link to={`/blog/post/${post.url}`}>
                    <button type="button" className={styles.button}>
                      View post
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default PostsCarousel;
