import React from "react";
import styles from "./NewestPosts.module.scss";
import { Link } from "gatsby";

const NewestPosts = ({ posts }) => {
  const { title, image, createdAt, description, url, author } = posts[
    posts.length - 1
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>NEWEST POST</h2>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <img src={image.fluid.src} className={styles.image} alt={title} />
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionHeader}>
            <h3>{title}</h3>
            <h4>{author}</h4>
            <h4>{createdAt}</h4>
          </div>
          <p>{description.substr(0, 100)}{"..."}</p>
          <div className={styles.buttonBox}>
            <Link to={`/blog/post/${url}`}>
              <button type="button" className={styles.button}>
                View post
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewestPosts;
