import React from "react";
import BlogNavigation from "./component/nav";
import NewestPosts from "./component/newestPosts";
import PostsCarousel from "./component/featuredPosts/postsCarousel";
import Posts from "./component/featuredPosts/posts";
import Banner from "./component/banner/Banner";
import styles from "./Blog.module.scss";

const Blog = ({ pageContext }) => {
  const posts = pageContext?.data?.posts ?? [];

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {'"The science of hops at your fingertips"'}
          </h2>
        </div>
        <BlogNavigation url={pageContext?.data?.url ?? "all"} />
        {posts && posts.length ? (
          <>
            <NewestPosts posts={posts} />
            {pageContext?.data?.url === "all" && <PostsCarousel posts={posts} />}
            <Posts posts={posts} showTitle={pageContext?.data?.url !== "all"}/>
          </>
        ) : (
          <Banner />
        )}
      </div>
    </div>
  );
};
export default Blog;
