import React from "react";
import { Link } from "gatsby";
import styles from "./Posts.module.scss";

const Posts = ({ posts, showTitle }) => {
  return (
    <div>
      {showTitle && <h2 className={styles.titleH2}>FEATURED POSTS</h2>}
      <div
        className={showTitle ? styles.containerWithoutSlider : styles.container}
      >
        {posts &&
          posts.map(post => (
            <div className={styles.post} key={post.id}>
              <div className={styles.imageContainer}>
                <img src={post.image.fluid.src} alt={post.title} />
              </div>
              <div className={styles.header}>
                <h3 className={styles.title}>{post.title}</h3>
                <h4>{post.author}</h4>
                <h4>{post.createdAt}</h4>
              </div>
              <div className={styles.description}>
                <p>
                  {post.description.substr(0, 80)}
                  {"..."}
                </p>
              </div>
              <div className={styles.buttonBox}>
                <Link to={`/blog/post/${post.url}`}>
                  <button type="button" className={styles.button}>
                    View post
                  </button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Posts;
